import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDragon } from '@fortawesome/pro-solid-svg-icons';
import Layout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';

export default function IndexPage(): JSX.Element{
  return (
    <Layout>
      <SEO title="Home" />
      <div className="flex bg-yellow-100 border-2 rounded border-yellow-400 p-4 mx-auto max-w-md text-yellow-900">
        <FontAwesomeIcon className="mr-3 mt-2" icon={faDragon} size="3x" />
        <div>
          <h2 className="uppercase font-bold tracking-wider">Mind the Traps</h2>
          <span>
            I haven&apos;t fully cleared out this dragon&apos;s lair yet, so you may find
            some dead links, illusory content, and glyphs of warding. Thanks for
            bearing with me!
          </span>
        </div>
      </div>
    </Layout>
  );
}
